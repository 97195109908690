import { useCallback, useEffect, useRef } from "react";
import { Menubar } from 'primereact/menubar';
import { Dropdown } from 'primereact/dropdown';
import { Loading } from "./Loading";
import { Link, Outlet, redirect, useLocation, useNavigate, useParams } from "react-router-dom";
import { MenuItem } from "primereact/menuitem";
import { useStoreActions, useStoreState } from "./store/hooks";
import { Season } from "./season";
import Draft from "./pages/draft";
import { Toast } from "primereact/toast";


const MenuLink = ({url, label, icon, active}: {url?: string, label: string, icon: string, active?: boolean}) => (
  <Link className={`no-underline hover:text-gray-900 p-menuitem-link hover:bg-slate-200 rounded-md ${active ? 'bg-slate-200 text-gray-900' : 'text-gray-500'}`} to={url || ''}>
      <span className={`p-menuitem-icon pi pi-fw ${icon}`} />
      <span className="p-menuitem-text">{label}</span>
  </Link>
)

const Navigation = () => {
  const potentialSeasons = useStoreState(state => state.potentialSeasons);
  const currentSeasonId = useStoreState(state => state.currentSeasonId);
  const selectSeason = useStoreActions((actions) => actions.selectSeason);
  const fetchSeason = useStoreActions(thunks => thunks.fetchSeasonData);
  const navigate = useNavigate();

  useEffect(() => {
      if (currentSeasonId) {
          fetchSeason(currentSeasonId);
      }
  }, [currentSeasonId, fetchSeason]);
  const { seasonId } = useParams();
  const location = useLocation();

  useEffect(() => {
      selectSeason(seasonId || '');
  }, [seasonId, selectSeason])

const activeRoute = useCallback((path:string) => {
  if (location.pathname === '/') {
    navigate('/season/2025/calendar');
    return false;
  }
  return location.pathname === path;
}, [location]);

  const model:MenuItem[] = [
      {
          template: <MenuLink
              url={`/season/${currentSeasonId || '2025'}/calendar`}
              active={activeRoute(`/season/${currentSeasonId}/calendar`)}
              label='Calendar'
              icon='pi-calendar'
          />
      },
      // {
      //     template: <MenuLink
      //         url={`/${currentSeasonId}/list`}
      //         active={activeRoute(`/${currentSeasonId}/list`)}
      //         label='List View'
      //         icon='pi-list'
      //     />
      // },
      {
          template: <MenuLink
              url={`/season/${currentSeasonId || '2025'}/forecast`}
              active={activeRoute(`/season/${currentSeasonId}/forecast`)}
              label='Forecast'
              icon='pi-table'
          />
      },
      {
          template: <MenuLink
              url={`/season/${currentSeasonId || '2025'}/league`}
              active={activeRoute(`/season/${currentSeasonId}/league`)}
              label='Leagues'
              icon='pi-table'
          />
      },
      {
          template: <MenuLink
              url={`/season/2025/draft`}
              active={activeRoute(`/season/${currentSeasonId}/draft`)}
              label='Draft'
              icon='pi-list'
          />
      },
      // {
          //     template: <MenuLink
          //         url={`/${currentSeasonId}/teams`}
          //         active={activeRoute(`/${currentSeasonId}/teams`)}
          //         label='Teams'
          //         icon = 'pi-flag'
          //     />
          // },
  ]
  // if (accountInfo) {
  //     model.push(
  //         {
  //             label: accountInfo,
  //             icon:'pi pi-fw pi-user',
  //             items:[
  //                 {
  //                     template: <MenuLink
  //                     url={'/account/settings'}
  //                     active={activeRoute(`account/settings`)}
  //                     label='Account Settings'
  //                     icon='pi pi-fw pi-cog'
  //                     />
  //                 },
  //                 {
  //                     template: <MenuLink
  //                         url={'/logout'}
  //                         label='Logout'
  //                         icon='pi pi-fw pi-sign-out'
  //                     />
  //                 },
  //                 {
  //                 },
  //             ]
  //         },
  //     );
  // } else {
  //     model.push(
  //         {
  //             label: 'Account',
  //             icon: 'pi pi-fw pi-user',
  //             items: [{
  //                 id: 'login',
  //                 template: <MenuLink
  //                 url={'/login'}
  //                 active={activeRoute(`login`)}
  //                 label='Log In'
  //                 icon='pi pi-fw pi-sign-in'
  //                 />
  //             },
  //             {
  //                 id: 'login',
  //                 template: <MenuLink
  //                     url={'/register'}
  //                     active={activeRoute(`register`)}
  //                     label='Create Account'
  //                     icon='pi pi-fw pi-user-plus'
  //                 />
  //             }]
  //             }
  //         )
  // }
      
//   const end = () => {
//       return (
//           <div className='flex'>
//               <Dropdown
//                   value={potentialSeasons.find(season => season.code === currentSeasonId)}
//                   onChange={(e) => {
//                       selectSeason(e.value.code);
//                       // const { query } = router as any;
//                       // let newRoute = router.pathname;
//                       // Object.keys(query).forEach((key: string) => {
//                       //     let value = query[key];
//                       //     if (key === 'seasonId') {
//                       //         value = e.value.code;
//                       //     }
//                       //     newRoute = newRoute.replace(`[${key}]`, value);
//                       // })
//                       // router.push(newRoute);
//                   }}
//                   options={potentialSeasons}
//                   optionLabel="name" 
//                   className="w-full md:w-14rem"
//               />
//           </div>
//       )
//   }

  return (
      <div>
          <header>
              <nav>
                  <Menubar model={model} />
              </nav>
          </header>
      </div>
  );
};


const Layout:any = () => {
    const toast = useRef<Toast>(null);

    const setMessager = useStoreActions((actions) => actions.setMessager);
    setMessager(toast);
    return (
        <>
          <Loading message='Loading' />
          <Navigation />
          <Toast ref={toast} />
          <main>
            <Outlet />
          </main>
          <div className={`invisible
                bg-pink-400 bg-pink-600
                bg-lime-400 bg-lime-600
                bg-sky-400 bg-sky-600
                bg-fuchsia-400 bg-fuchsia-600
                bg-yellow-400 bg-yellow-600
                bg-red-400 bg-red-600
                bg-orange-400 bg-orange-600
                bg-amber-400 bg-amber-600
                bg-cyan-400 bg-cyan-600
                bg-violet-400 bg-violet-600
                bg-zinc-400 bg-zinc-500`
            }></div>
      </>
  );
}

export default Layout;