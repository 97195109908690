import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller, DefaultValues } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';

import { ErrorMessage } from "@hookform/error-message"

import axios from 'axios';
import { Toast } from 'primereact/toast';

import css from '../../styles/forms.module.scss';
import { useNavigate } from 'react-router-dom';
import { createAccount } from '../../axios';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FloatLabel } from 'primereact/floatlabel';
import { useStoreState } from '../../store/hooks';

type FormData = {
    name: string,
    email: string,
    password: string,
};

export default function Page() {
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState<FormData>();
    const navigate = useNavigate();
    const messager = useStoreState(state => state.messager);

    const defaultValues: FormData = {
        name: '',
        email: '',
        password: '',
    };

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    
    const toast = useRef<Toast>(null);
    const onSubmit = (data: FormData) => {
        setFormData(data);
        setShowMessage(true);
        createAccount(data)
        .then(response => {
            if (response.data.success) {
                messager.current?.show({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
                navigate('/login');
            } else {
                messager.current?.show({severity:'error', summary: 'Error', detail: 'There was an error creating your account', life: 3000});
            }
        }).catch(error => {
            console.log('error', error)
            messager.current?.show({severity:'error', summary: 'Error', detail:error.response.data.message, life: 3000});
        })
        reset();
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="flex flex-col items-center xh-full">
            <Toast ref={toast} />
            <Panel header="Register" className={css.panel}>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className={css.field}>
                            <Controller name="name" control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                                <IconField iconPosition="left">
                                    <InputIcon className='pi pi-user' />
                                    <InputText id={field.name} {...field} placeholder='Name' className={classNames({ 'p-invalid': fieldState.invalid })} />
                                </IconField>
                            )} />
                            {errors.name && <small className="p-error">{errors.name.message}</small>}
                        </div>
                        <div className={css.field}>
                            <Controller name="email" control={control}
                                rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                render={({ field, fieldState }) => (
                                    <IconField iconPosition="left">
                                        <InputIcon className='pi pi-envelope' />
                                        <InputText id={field.name} {...field} placeholder='Email' className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    </IconField>
                            )} />
                            {errors.email && <small className="p-error">{errors.email.message}</small>}
                        </div>
                        <div className={css.field}>
                            <span className="p-float-label">
                                <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                    <FloatLabel>
                                        <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        <label htmlFor="password" className={classNames({ 'p-error': errors.password })}><InputIcon className='pi pi-key pr-3' />
                                        Password</label>
                                    </FloatLabel>
                            )} />
                            </span>
                            {errors.password && <small className="p-error">{errors.password.message}</small>}
                        </div>
                        <Button type="submit" severity='info' label="Submit" className="mt-2" />
                    </form>
            </Panel>
        </div>
    );
}
             