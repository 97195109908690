import { useMemo } from "react";
import { usePotentialCupGames } from "../../hooks";
import moment from "moment";
import { Panel } from "primereact/panel";
import { useStoreState } from '../../store/hooks';
import { metadata } from "../../potentialSeasons";

export const DaysRemaining = () => {
    const {
        potentialCupGames: potentialCupGamesEntireSeason,
    } = usePotentialCupGames(true);
    const currentSeasonId = useStoreState(state => state.currentSeasonId);
      
    const daysRemaining = useMemo(() => {
        if (potentialCupGamesEntireSeason && potentialCupGamesEntireSeason.length) {
            const season = metadata.find(data => data.code === currentSeasonId.toString());
            const endDate = season?.endDate;
            const nextGameDate = potentialCupGamesEntireSeason[0].date;
            return moment(endDate).diff(nextGameDate, 'days');
        }
    }, [currentSeasonId, potentialCupGamesEntireSeason]);

    if (daysRemaining) {
        return (<Panel className="m-4" header='Potential Cup Days Left'>
            <div className="flex justify-center items-center">
            {daysRemaining || '0 Days remaining'}
            </div>
        </Panel>)
    }
    return null;
}