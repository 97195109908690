
import React, { useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon'

import css from '../../styles/forms.module.scss';


import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { FloatLabel } from 'primereact/floatlabel';
import { login } from '../../axios';

type FormData = {
    email: string,
    password: string,
};

export default function LoginPage() {
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState<FormData>();
    const navigate = useNavigate();
    const setUser = useStoreActions((actions) => actions.setUser);
    const messager = useStoreState(state => state.messager);

    const defaultValues: FormData = {
        email: '',
        password: '',
    };

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    
    const onSubmit = (data: FormData) => {
        setFormData(data);
        setShowMessage(true);

        login(data)
        .then(response => {
            if (response?.data.success) {
                setUser(response.data.responseObject);
                messager?.current.show({severity:'success', summary: 'Success', detail:response?.data.message, life: 3000})
                navigate('/season/2024/calendar');
            }
        }).catch(error => {
            messager?.current.show({severity:'error', summary: 'Error', detail:error.response.data.message, life: 3000});
        })
        reset();
    };

    return (
        <div className="flex flex-col items-center xh-full">
            <Panel header="Login" className={css.panel}>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className={css.field}>
                                <Controller name="email" control={control}
                                    render={({ field, fieldState }) => (
                                        <IconField iconPosition="left">
                                            <InputIcon className='pi pi-envelope' />
                                            <InputText id={field.name} {...field} placeholder='Email' className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        </IconField>
                                )} />
                            {errors.email && <small className="p-error">{errors.email.message}</small>}
                        </div>
                        <div className={css.field}>
                                <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                    <FloatLabel>
                                        <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        <label htmlFor="password" className={classNames({ 'p-error': errors.password })}><InputIcon className='pi pi-key pr-3' />
                                        Password</label>
                                    </FloatLabel>
                                )} />
                            {errors.password && <small className="p-error">{errors.password.message}</small>}
                        </div>
                        <Button type="submit" severity='info' label="Submit" className="mt-2" />
                    </form>
            </Panel>
        </div>
    );
}
             