import { thunk, createStore, action } from 'easy-peasy';
import { SeasonStore } from './model'; 
import { potentialSeasons, domain } from '../util';
import axios from 'axios';
import { getSeasonResults } from '../axios';

export const store = createStore<SeasonStore>({
    currentSeasonId: '2025',
    accountName: '',
    isLoading: false,
    potentialSeasons,
    seasonResults: {},
    teamTotals: {},
    seasons: {},
    messager: {},
    setMessager: action ((state, payload) => {
        state.messager = payload;
    }),
    setIsLoading: action ((state, payload) => {
        state.isLoading = payload;
    }),
    selectSeason: action ((state, payload) => {
        state.currentSeasonId = payload;
    }),
    setUser: action ((state, payload) => {
        state.accountName = payload.name;
    }),
    getUser: thunk (async (state) => {
        try {
            // const response = await axios.get('/api/account/me');
            // state.setUser(response.data?.user);
        } catch(e) {
        }
    }),
    setSeasonData: action((state, payload) => {
        state.seasons[payload.seasonId] = payload.schedule;
        state.seasonResults[payload.seasonId] = payload.seasonResults;
        state.teamTotals[payload.seasonId] = payload.teamTotals;
    }),
    fetchSeasonData: thunk (async (state, seasonId ='2025') => {
        state.selectSeason(seasonId);
        state.setIsLoading(true);
        const start = Date.now();
        const {data: {responseObject: { schedule, results, teamTotals }}} = await getSeasonResults(seasonId);
        state.setSeasonData({
            seasonId,
            schedule,
            seasonResults: results,
            teamTotals
        });
        setTimeout(() => {
            state.setIsLoading(false);
        }, 250 - (Date.now() - start))
    }),
});
